

<template>
  <div class="draw-io-container">
    <iframe
      v-if="readySrc"
      frameborder="0"
      style="width: 100%; height: 100%"
      :src="readySrc"
    ></iframe>
  </div>
</template>
    
    <script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      readySrc: "",
    };
  },
  mounted() {
    this.prepareContent();
  },
  methods: {
    prepareContent() {
      fetch(this.src)
        .then((response) => response.text())
        .then((data) => {
          const encodedData = encodeURIComponent(data);
          this.readySrc = `https://viewer.diagrams.net/?tags=%7B%7D&highlight=0000ff&edit=_blank&layers=1&nav=1#R${encodedData}`;
        })
        .catch((error) => console.error(error));
    },
  },
};
</script>
  
  
<style lang="scss" scoped>
.draw-io-container {
  min-height: 700px;
  min-width: 700px;
  width: 100%;
  height: 100%;
}
</style>
   
  
  
  
    