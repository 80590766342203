<template>
  <AView
    :value="value"
    :name="file.name"
    :active="id === activeFileId"
    @onHover="() => onHover(file)"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
  >
    <template v-slot:content="{}">
      <!-- <pdf
        :src="file.url"
        :page="1"
        v-if="file.type === 'application/pdf'"
      >
        <template slot="loading"> loading content here... </template>
      </pdf> -->

      <PDFPreview
        v-if="file.type === 'application/pdf'"
        :src="file.url"
      ></PDFPreview>
      <DrawIoPreview
        v-else-if="DRAW_IO_MIMETYPES.find((t) => t === file.type)"
        :src="file.url"
      ></DrawIoPreview>
      <PPTXPreview
        v-else-if="POWERPOINT_MIMETYPES.find((t) => t === file.type)"
        :value="file"
      ></PPTXPreview>

      <v-img
        v-else-if="IMAGES_MIMETYPES.find((t) => t === file.type)"
        :lazy-src="file.url"
        :src="file.url"
      ></v-img>
    </template>
  </AView>
</template>
        
        
        
<script>
// import pdf from "pdfvuer";
import { mapState } from "vuex";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
import { FOLDER_BUTTONS } from "../constants/actions.gh";
import {
  DRAW_IO_MIMETYPES,
  IMAGES_MIMETYPES,
  POWERPOINT_MIMETYPES,
} from "../constants/mimetypes";
import { PermissionsHelper } from "../../../../../components/helpers/permissions.helper";
import PDFPreview from "../components/preview/PDFPreview.vue";
import DrawIoPreview from "../components/preview/DrawIoPreview.vue";
import PPTXPreview from "../components/preview/PPTXPreview.vue";

export default {
  name: "FilePreview",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    // pdf,
    PDFPreview,
    DrawIoPreview,
    PPTXPreview,
  },
  computed: {
    ...mapState("FileStore", ["activeFileId", "files"]),
    actions: function () {
      return PermissionsHelper.getActions(
        [FOLDER_BUTTONS.CREATE_NEW, FOLDER_BUTTONS.BULK_SELECT],
        this.value.permissions
      );
    },
  },
  mounted() {
    this.$store.dispatch("FileStore/GetFile", {
      id: this.id,
      u_type: this.u_type,
      refresh: true,
    });

    this.file = this.files[this.id]
      ? this.files[this.id]
      : {
          name: "",
        };

    this.watcher = this.$watch(
      `files.${this.id}`,
      (newVal) => {
        if (!newVal) return;

        this.file = newVal;
      },
      {
        deep: true,
      }
    );
  },
  beforeDestroy() {
    if (this.watcher) this.watcher();
  },
  data() {
    const id =
      this.value.relation && this.value.relation.params
        ? this.value.relation.params.fileId
        : "home";

    const u_type =
      this.value.relation && this.value.relation.params
        ? this.value.relation.params.u_type
        : "unknown";

    return {
      id,
      u_type,
      file: {},
      watcher: undefined,
      IMAGES_MIMETYPES,
      DRAW_IO_MIMETYPES,
      POWERPOINT_MIMETYPES,
    };
  },
  methods: {
    onErr(err) {
      console.log("WTF??? : ", err);
    },
    onHover(value) {
      this.$store.commit("FileStore/setActiveId", value.id);
    },
  },
};
</script>
        
        
        
  <style scoped lang="scss" >
.scroll-row {
  height: calc(100% - 110px);
}
</style>