

<template>
  <div class="google-slides-container">
    <iframe
      frameborder="0"
      style="width: 100%; height: 100%"
      :src="`https://docs.google.com/presentation/d/${value.gd_code}/edit`"
    ></iframe>
  </div>
</template>
      
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  data() {
    return {};
  },
  mounted() {
  },
  methods: {},
};
</script>
    
    
  <style lang="scss" scoped>
.google-slides-container {
  min-height: 700px;
  min-width: 700px;
  width: 100%;
  height: 100%;
}
</style>
     
    
    
    
      